import React, { Component } from "react"
import Img from "gatsby-image"
import { Link } from "gatsby"
import axios from "axios"

class Footer extends Component {
  constructor(props) {
    super(props)
    const x = Math.floor(Math.random() * 10)
    const y = Math.floor(Math.random() * 10)

    const question = `What is ${x} + ${y}`
    this.state = {
      roboQuestion: {
        x,
        y,
        question,
      },

      labels: {
        name: "form-label",
        phone: "form-label",
        email: "form-label",
        question: "form-label",
      },
      placeHolders: {
        name: "Name",
        phone: "Phone",
        email: "Email",
        question: "Prove you're not a bot...",
      },
      inputs: {
        name: "",
        phone: "",
        email: "",
        question: "",
        resume: "",
      },
      values: {
        name: "",
        phone: "",
        email: "",
        question: "",
        resume: "",
      },
      submitting: {
        processing: false,
        btnClass: "btn btn-primary btn-primary__blue",
      },

      message: {
        show: false,
        class: "message-content",
        message: "",
      },
    }

    this.handleFocus = this.handleFocus.bind(this)
    this.handleBlur = this.handleBlur.bind(this)
    this.handleInputChange = this.handleInputChange.bind(this)
    this.handleValidate = this.handleValidate.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.closeMessage = this.closeMessage.bind(this)
  }

  handleFocus(event) {
    const target = event.target.name

    this.setState({
      labels: {
        ...this.state.labels,
        [target]: "form-label form-label__active",
      },
      placeHolders: {
        ...this.state.placeHolders,
        [target]: "",
      },
      inputs: {
        ...this.state.inputs,
        [target]: "form-input form-input__active",
      },
    })
  }
  handleBlur(event) {
    const target = event.target.name

    this.setState({
      labels: {
        ...this.state.labels,
        [target]:
          this.state.values[target] === ""
            ? "form-label"
            : "form-label form-label__value",
      },
      placeHolders: {
        ...this.state.placeHolders,
        [target]: this.state.values[target] === "" ? target : "",
        question:
          this.state.values.question === "" ? "Prove you're not a bot..." : "",
      },
      inputs: {
        ...this.state.inputs,
        [target]:
          this.state.values[target] === "" ? "form-input" : "form-input__value",
      },
    })
  }

  handleInputChange(event) {
    const target = event.target.name
    const value = event.target.value
    this.setState({
      values: {
        ...this.state.values,
        [target]: value,
      },
    })

   
  }
  handleValidate(event) {
    event.preventDefault()
    const { values, roboQuestion } = this.state
    const mailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
    const answer = roboQuestion.x + roboQuestion.y
    let forVal = {}
    let errors = false

    for (const [key, value] of Object.entries(values)) {
      if (value === "") {
        forVal[key] = "form-input form-input__error"
        errors = true
      }

      if (value !== "") {
        forVal[key] = "form-input form-input__value"
      }

      if (key === "question" && parseInt(value) !== answer) {
        forVal[key] = "form-input form-input__error"
        errors = true
      }
      if (key === "email") {
        if (!value.match(mailRegex)) {
          forVal[key] = "form-input form-input__error"
          errors = true
        }
      }
    }

    if (errors) {
      console.log("errors ====>", errors)
      return this.setState({
        inputs: forVal,
      })
    }

    if (!errors) {
  
      this.handleSubmit()
    }
  }

  handleSubmit() {
    console.log("Submitting ===>")
    const x = Math.floor(Math.random() * 10)
    const y = Math.floor(Math.random() * 10)
    const resume = document.getElementById("resumeFile").files[0];
    const body = new FormData();
    const question = `What is ${x} + ${y}`
    const { values } = this.state
    values.resume = resume
    for (const [key, value] of Object.entries(values)) {
      if(key !== 'resume'){
        console.log(`${key}: ${value}`)
        body.append(key, value)
      }
    }
    
    console.log("Submitting ===>", body)
    body.append('resume', resume)

    this.setState({
      submitting: {
        processing: true,
        btnClass: "btn btn-disabled",
      },
    })

    return axios
      .post("https://api-balcon.herokuapp.com/api/careers-form", body)
      .then(res => {
     
        if (res.status === 200) {
          return this.setState({
            roboQuestion: {
              x,
              y,
              question,
            },
            submitting: {
              processing: false,
              btnClass: "btn btn-primary btn-primary__blue",
            },
            labels: {
              name: "form-label",
              phone: "form-label",
              email: "form-label",
              question: "form-label",
            },
            placeHolders: {
              name: "Name",
              phone: "Phone",
              email: "Email",
              question: "Question",
            },
            inputs: {
              name: "",
              phone: "",
              email: "",
              question: "",
              resume: ""
            },
            values: {
              name: "",
              phone: "",
              email: "",
              question: "",
              resume: ""
            },
            message: {
              show: true,
              class: "message-content message-content__success",
              message:
                "Success! We have received your resumé and we will be in touch soon. If you need immediate assistance, you can call us at 712-515-0232",
            },
          })
        }
      })
      .catch(err => {
        console.log("Catch ====>", err)
        this.setState({
          message: {
            show: true,
            class: "message-content message-content__error",
            message:
              "Error! We did not receive your resumé. You can try to submit the form again or If you need immediate assistance, you can call us at 712-515-0232",
          },
          submitting: {
            processing: false,
            btnClass: "btn btn-primary btn-primary__blue",
          },
        })
      })
  }

  closeMessage() {
    this.setState({
      message: {
        show: false,
      },
    })
  }
  render() {
    const {
      labels,
      placeHolders,
      inputs,
      values,
      submitting,
      message,
    } = this.state

    return (
      <>
        {message.show ? (
          <div className="message">
            <div className={message.class}>
              <button
                className="btn btn-message"
                onClick={this.closeMessage.bind(this)}
              >
                X
              </button>
              <p className="main-type main-type__white main-type__body">
                {message.message}
              </p>
            </div>
          </div>
        ) : (
          ""
        )}

        <div className="u-footer-spacer">
          <h2
            className="main-type main-type__black main-type__heavy main-type__header-primary u-center-text
   
      "
          >
            Ready to Apply?
          </h2>
        </div>

        <div className="footer u-mt-space">
          <div className="card-schedule grid grid-no-padding grid-two-col grid-no-gap">
            <div className="card-schedule__contact u-pos-rel">
              <div className="u-p-3 grid-footer-contact">
                <div>
                  <h2 className="main-type main-type__heavy main-type__white main-type__header-secondary">
                    Application Form
                  </h2>
                  <p className="main-type main-type__white main-type__body u-mt-2">
                    Please fill out the form and upload your resumé. This form
                    will only accept one .pdf, .doc, or .docx file.
                  </p>
                </div>
                <div>
                  <div className="card-title__container u-mt-2">
                    <div className="img-footer-icon">
                      <Img
                        fluid={this.props.callIcon}
                        alt="BalCon Technician Review Air Specifications"
                      />
                    </div>

                    <p className="main-type main-type__white main-type__body">
                    <a href="tel:712-309-3680">(712)-309-3680</a> 
                    </p>
                  </div>
                  <div className="card-title__container u-mt-2">
                    <div className="img-footer-icon">
                      <Img
                        fluid={this.props.envelopeIcon}
                        alt="BalCon Technician Review Air Specifications"
                      />
                    </div>

                    <p className="main-type main-type__white main-type__body">
                      balconsales@balcontab.com
                    </p>
                  </div>
                  <div className="card-title__container u-mt-2">
                    <div className="img-footer-icon">
                      <Img
                        fluid={this.props.mapIcon}
                        alt="BalCon Technician Review Air Specifications"
                      />
                    </div>

                    <p className="main-type main-type__white main-type__body">
                      3211 Nebraska Ave <br />
                      Council Bluffs, Iowa <br />
                      51501
                    </p>
                  </div>
                </div>
              </div>
              <div className="img-footer-blob">
                <Img
                  fluid={this.props.footerBlob}
                  alt="BalCon Technician Review Air Specifications"
                />
              </div>
            </div>
            <div className="card-schedule__form" id="#contactForm">
              <form onSubmit={this.handleValidate.bind(this)} >
              <div className="grid grid-two-col">
                <div className="form-group">
                  <label
                    htmlFor="name"
                    className={`main-type main-type__form ${labels.name}`}
                  >
                    {" "}
                    Name
                  </label>
                  <input
                    type="text"
                    name="name"
                    className={`main-type main-type__body main-type__secondary form-input ${inputs.name}`}
                    placeholder={placeHolders.name}
                    onFocus={this.handleFocus.bind(this)}
                    onBlur={this.handleBlur.bind(this)}
                    onChange={this.handleInputChange.bind(this)}
                    label="name"
                    value={values.name || ""}
                  />
                </div>
                <div className="form-group">
                  <label
                    htmlFor="phone"
                    className={`main-type main-type__form ${labels.phone}`}
                  >
                    {" "}
                    Phone
                  </label>
                  <input
                    type="phone"
                    name="phone"
                    className={`main-type main-type__body main-type__secondary form-input ${inputs.phone}`}
                    placeholder={placeHolders.phone}
                    onFocus={this.handleFocus.bind(this)}
                    onBlur={this.handleBlur.bind(this)}
                    onChange={this.handleInputChange.bind(this)}
                    label="phone"
                    value={values.phone || ""}
                  />
                </div>
                <div className="form-group">
                  <label
                    htmlFor="email"
                    className={`main-type main-type__form ${labels.email}`}
                  >
                    {" "}
                    Email
                  </label>
                  <input
                    type="email"
                    name="email"
                    className={`main-type main-type__body main-type__secondary form-input ${inputs.email}`}
                    placeholder={placeHolders.email}
                    onFocus={this.handleFocus.bind(this)}
                    onBlur={this.handleBlur.bind(this)}
                    onChange={this.handleInputChange.bind(this)}
                    label="email"
                    value={values.email || ""}
                  />
                </div>
              </div>
              <div style={{ padding: "0rem 2rem 4rem 2rem" }}>
                <div className="form-group">
                  <input
                    accept=".pdf, .doc, .docx"
                    multiple={false}
                    type="file"
                    name="resume"
                    onChange={this.handleInputChange.bind(this)}
                    label="resume"
        
                    id="resumeFile"
                  />
                  <p className="main-type main-type__body main-type__red">
                    {inputs.resume === "" ||
                    inputs.resume === "form-input form-input__value" ||
                    values.resume !== ""
                      ? ""
                      : "Missing File"}
                  </p>
                </div>
                <div className="form-group u-mt-3">
                  <label
                    htmlFor="question"
                    className={`main-type main-type__form ${labels.question}`}
                  >
                    {" "}
                    {this.state.roboQuestion.question}
                  </label>
                  <input
                    type="number"
                    name="question"
                    className={`main-type main-type__body main-type__secondary form-input ${inputs.question}`}
                    placeholder={placeHolders.question}
                    onFocus={this.handleFocus.bind(this)}
                    onBlur={this.handleBlur.bind(this)}
                    onChange={this.handleInputChange.bind(this)}
                    label="question"
                    value={values.question || ""}
                  />
                </div>
                <button
                  className={submitting.btnClass}
                  style={{ float: "right", margin: "1rem 0rem 1.5rem 0rem" }}
                  type="submit"
                  disabled={submitting.processing}
                >
                  Send
                </button>
              </div>
              </form>
            </div>
          </div>

          <div className="footer-container">
            <div className="grid-footer">
              <div className="img-footer-container">
                <Link to="/">
                  <Img
                    fixed={this.props.balconLogo}
                    alt="Balcon white logo against a dark background"
                  />
                </Link>
                <a href="https://rasmech.com" rel="noreferrer" target="__blank">
                  <Img
                    fixed={this.props.rmsLogo}
                    alt="Rasmussen Mechanical Services white logo against a dark background"
                  />
                </a>
              </div>
              <div className="footer-links-container">
                <h3 className="main-type main-type__heavy main-type__white main-type__header-tertiary u-mb-1">
                  Quick Links
                </h3>
                <ul>
                  <li className="main-type main-type__white u-mb-1">
                    <Link to="/services/lab-safety">Lab Safety</Link>
                  </li>
                  <li className="main-type main-type__white u-mb-1">
                    <Link to="/services/tab-testing">TAB Testing</Link>
                  </li>
                  <li className="main-type main-type__white u-mb-1">
                    <Link to="/blog">Blog</Link>
                  </li>
                  <li className="main-type main-type__white u-mb-1">
                    <Link to="/careers">Careers</Link>
                  </li>
                </ul>
              </div>
              <div className="contact-links-container">
                <h3 className="main-type main-type__heavy main-type__white main-type__header-tertiary u-mb-1">
                  Contact Us
                </h3>
                <ul>
                  <li className="main-type main-type__white u-mb-1">
                  <a href="tel:712-309-3680">(712)-309-3680</a> 
                  </li>
                  <li className="main-type main-type__white u-mb-1">
                    balconsales@balcontab.com
                  </li>
                  <li className="main-type main-type__white u-mb-1">
                    3211 Nebraska Ave, Council Bluffs, IA 51501
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }
}

export default Footer
