import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import lsBlob from "assets/images/lab-safety-blob.svg"
import tabBlob from "assets/images/tab-testing-blob.svg"

const getCareers = graphql`
  {
    allStrapiCareers {
      edges {
        node {
          id
          Position
          File {
            publicURL
          }
          Description
          Department
        }
      }
    }
  }
`

export default function Careers() {
  const careerData = useStaticQuery(getCareers)
  const positions = careerData.allStrapiCareers.edges

  return positions.length === 0 ? (
    <div className=" careers u-center-text">
      <h2 className="main-type main-type__heavy main-type__black main-type__header-primary">
        There are no current openings.
      </h2>
    </div>
  ) : (
    <div className="careers u-center-text">
      <h2 className="main-type main-type__heavy main-type__black main-type__header-primary">
        Career Openings
      </h2>

      {positions.map(d => {
        const { node } = d
        return (
          <div className="grid grid-two-col" key={node.id}>
            <div>
              <h3 className="main-type main-type__black main-type__heavy main-type__header-secondary">
                {node.Position}
              </h3>
              <p className="main-type main-type__black main-type__body">
                {node.Description}
              </p>
              <div className="u-mt-3">
                <a
                  className="btn btn-primary btn-primary__blue "
                  href={`${node.File.publicURL}`}
                  target="__blank"
                >
                  Download Description
                </a>
              </div>
            </div>
            <div className="grid-reverse careers-img-container">
              <img
                src={node.Department === "Lab" ? lsBlob : tabBlob}
                className="careers-img"
                alt={
                  node.Department === "Lab"
                    ? "Lab Safety career gradient blob"
                    : "TAB career gradient blob"
                }
              />
            </div>
          </div>
        )
      })}
    </div>
  )
}
