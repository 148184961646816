import React from "react"
import BackgroundImage from "gatsby-background-image"
import { useStaticQuery, graphql } from "gatsby"
import BannerLab from "components/BannerLab"
import CareersList from "components/CareersList"
import Seo from "components/Seo"
import Header from "components/Header"
import CareersFooter from "components/CareersFooter"

const getImages = graphql`
  {
    bgImage: file(
      relativePath: { eq: "balcon-lab-safety-tab-testing-careers.webp" }
    ) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    seoImg: file(relativePath: { eq: "placeholder-lab.webp" }) {
      childImageSharp {
        fixed(width: 400, height: 250) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    callIcon: file(relativePath: { eq: "call.webp" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    envelopeIcon: file(relativePath: { eq: "envelope.webp" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    mapIcon: file(relativePath: { eq: "maps-and-flags.webp" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    footerBlob: file(relativePath: { eq: "footerBlob.webp" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    balconLogo: file(relativePath: { eq: "balcon-logo-white.png" }) {
      childImageSharp {
        fixed(height: 50) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    rmsLogo: file(relativePath: { eq: "rms-logo-white.png" }) {
      childImageSharp {
        fixed(height: 75) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`

const Careers = () => {
  const imgData = useStaticQuery(getImages)

  return (
    <>
      <div className="container">
        <div className="wrapper">
          <Header />
          <Seo
            title="Lab Safety and TAB Testing Technician Careers"
            description="Our Biological Safety Cabinet Certification Team has become the leader in comprehensive testing, certification and service. Read more about our certifications and services."
            keywords="lab safety technician, TAB career, tab technician, pharmacy technician"
            pathname="/careers/"
            image={imgData.seoImg.childImageSharp.fluid}
          />
          <article>
            <BackgroundImage
              Tag="section"
              fluid={imgData.bgImage.childImageSharp.fluid}
              className="banner-service"
            >
              <BannerLab
                title="Join the BalCon Family"
                body1="BalCon strives to be the preferred balancing and lab safety company in the industry. To do that, we need skilled experts who love to see our clients succeed in all their goals. If you don't see an opening that fits your skillset, but have a passion for the work BalCon does, please fill out the form below and attach a resumé or any applicable documents. We're always looking for ways to grow our team in the right direction. 
            "
                linkText="View Careers"
                link="/careers#careersList"
              />
            </BackgroundImage>
            {/* Anchor for Smooth Scroll */}
            <section id="careersList">
              <div className="u-mt-3">
                <CareersList />
              </div>
            </section>
          </article>
          <CareersFooter
            callIcon={imgData.callIcon.childImageSharp.fluid}
            envelopeIcon={imgData.envelopeIcon.childImageSharp.fluid}
            mapIcon={imgData.mapIcon.childImageSharp.fluid}
            footerBlob={imgData.footerBlob.childImageSharp.fluid}
            balconLogo={imgData.balconLogo.childImageSharp.fixed}
            rmsLogo={imgData.rmsLogo.childImageSharp.fixed}
          />
        </div>
      </div>
    </>
  )
}

export default Careers
