import React from "react"
import { AnchorLink } from "gatsby-plugin-anchor-links"
import Button from "components/Button"
export default function BannerLab({ title, body1, body2, link, linkText }) {
  return (
    <div className="banner-service__heading">
      <h1 className="main-type main-type__heavy main-type__white main-type__header-primary">
        {title}
      </h1>
      <p className="main-type main-type__white main-type__body ">{body1}</p>
      <p className="main-type main-type__white main-type__body u-mt-1">
        {body2}
      </p>
      <AnchorLink to={link} title="Schedule Service">
        <Button
          text={linkText ? linkText : "Schedule Service"}
          color="green"
          primary={true}
          margin="u-mt-3"
          gTracking={true}
          pageName="lab-equipment"
        />
      </AnchorLink>
    </div>
  )
}
